import React, {useState} from 'react';
import { Container, Modal, Row, Col} from 'react-bootstrap';
import logo from '../Assets/Logo.png';
import {List} from 'react-bootstrap-icons';

import appStore from '../Assets/AppStore.svg';
import background from '../Assets/blobBackground.jpeg'
import phones from '../Assets/phones.png'
function Home() {
  const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div style={{backgroundImage:'url('+background+')', minHeight:'100vh', backgroundSize:'100% 100%'}}>
            
            <Container fluid='md'>
                <Row>
                    <Col>
                        <div style={{paddingTop:40}}>
                        <List size={50} style={{marginRight:15}} onClick={() => {handleShow()}}/>

                            <img src={logo} alt={''} style={{width:130}}/>
                        </div>
                    </Col>
                    {false && <Col >
                        <div style={{float:'right',paddingTop:40}}>
                            {<img src={appStore} alt={''} />}
                        </div>
                    </Col>}
                </Row>
            </Container>
            <div style={{alignItems:'center',display:'flex', justifyContent:'center'}}>
                    <img src={phones} alt={''} style={{maxHeight:'80vh', maxWidth:'90vw'}}/>  
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Body >Myddle is revolutionizing the way you hangout with your friends. Built for both the spontaneous and meticulous, we’ll make it fun and easy to plan events. 
                <br/>
                <br/>
                Users create events with their friends and Myddle suggests the best places to hangout based on everyone’s time and event preferences and current locations.
                <br/>
                <br/>
                Coming soon to the Apple App Store and Google Play!
                </Modal.Body>
                
                </Modal.Header>
            </Modal>
    </div>
    );
}

export default Home;
