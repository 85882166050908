import logo from './Assets/Logo.png';
import './App.css';
import { BrowserRouter, HashRouter, Route, Switch} from 'react-router-dom';

import Home from './Screens/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
function App() {
  return (
    <div>
      <BrowserRouter>
          <div>
            <Switch>
              <Route path='/' component={Home} exact={true} />
            </Switch>
          </div>
      </BrowserRouter>
      </div>
  );
}

export default App;
